// @ts-strict-ignore
import { send } from 'loot-core/platform/client/fetch';
import * as Platform from 'loot-core/src/client/platform';

function parseSemanticVersion(versionString): [number, number, number] {
  return versionString
    .replace('v', '')
    .split('.')
    .map(n => parseInt(n));
}

function cmpSemanticVersion(
  versionStringA: string,
  versionStringB: string,
): number {
  const x = parseSemanticVersion(versionStringA);
  const y = parseSemanticVersion(versionStringB);

  return x[0] - y[0] || x[1] - y[1] || x[2] - y[2];
}

// We check against the server, not against github
export async function getLatestVersion(): Promise<string | 'unknown'> {
  if (Platform.isPlaywright) {
    return Promise.resolve('v99.9.9');
  }

  try {
    const result = await send('get-server-version');
    if ('version' in result) {
      return result.version as string;
    } else {
      return 'unknown';
    }
  } catch {
    // Rate limit exceeded? Or perhaps Github is down?
    return 'unknown';
  }
}

export async function getIsOutdated(latestVersion: string): Promise<boolean> {
  const clientVersion = window.Actual?.ACTUAL_VERSION;
  if (latestVersion === 'unknown') {
    return Promise.resolve(false);
  }
  return cmpSemanticVersion(clientVersion, latestVersion) < 0;
}
