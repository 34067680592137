import { useAccounts } from './useAccounts';

const maxLinkedAccounts = 3;

export const useLinkedAccountsLimit = () => {
  const accounts = useAccounts();
  const linkedAccountsLimitReached =
    accounts.filter(a => !!a.bank).length >= maxLinkedAccounts;

  return { linkedAccountsLimitReached };
};
